import React from "react";
import Link from "next/link";

export default function Agreements() {
  return (
    <div className="text-center text-[10px] tracking-tight font-light text-textLightColor sm:text-sm sm:tracking-normal">
      By signing up, you are agreeing to our{" "}
      <Link className="underline" target="_blank" href="/terms.html">
        Terms of Service
      </Link>{" "}
      and{" "}
      <Link className="underline" target="_blank" href="/privacy.html">
        Privacy Policy
      </Link>
      .
    </div>
  );
}
