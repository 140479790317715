import Image from "next/image";
import homeIcon from "../../../../public/statics/images/homeIcon.png";

export default function SignInTitle() {
  return (
    <div className="flex flex-col items-center justify-center gap-2 text-center sm:gap-4">
      <Image
        src={homeIcon}
        alt="home icon"
        className="h-14 w-14 sm:h-28 sm:w-28"
      />
      <div className="flex flex-col items-center space-y-4 text-center">
        <h1 className="text-5xl font-bold tracking-tight text-gray-900 sm:text-6xl">
          Sopi<span className="text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-500">.ai</span>
        </h1>
        <p className="text-lg font-light sm:text-xl text-transparent bg-clip-text bg-gradient-to-r from-pink-400 to-purple-400">
          Seed of personal inspiration
        </p>
        <p className="mt-2 font-medium text-gray-800 max-w-full px-4" style={{
          fontSize: 'clamp(16px, 5vw, 30px)',
          lineHeight: '1.2',
          wordBreak: 'keep-all',
          overflowWrap: 'break-word'
        }}>
          유튜버와 대화하고 영감을 나눠보세요
        </p>
      </div>
    </div>
  );
}