import ChromeIcon from "../../common/icons/ChromeIcon";
import SafariIcon from "../../common/icons/SafariIcon";

export default function AlertOpenInSystemBrowser() {
  return (
    <div className="rounded-3xl border-[0.6px] border-textColor bg-white px-4 py-5 text-center text-sm">
      <span className="font-bold">
        더보기(···)에서 &#39;외부 브라우저에서 열기&#39;를 클릭하세요{" "}
      </span>{" "}
      <p>우측 상단 또는 하단을 확인하세요</p>
      <p className="py-4">또는</p>
      <p className="font-bold">
        아래 브라우저에서 <span className="underline">https://sopi.ai</span> 에 접속하세요
      </p>
      <div className="flex justify-center gap-2 pt-3 text-[0.5rem] text-textLightColor">
        <div className="flex flex-col justify-between">
          <SafariIcon />
          Safari
        </div>
        <div className="flex flex-col justify-between">
          <ChromeIcon />
          Chrome
        </div>
      </div>
    </div>
  );
}
