import React, { useState, useEffect } from "react";

export default function OpenInSystemBrowserButton() {
  const [origin, setOrigin] = useState("");
  useEffect(() => {
    setOrigin(window.location.origin);
  }, []);

  const openExternalBrowser = () => {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.match(/kakaotalk/i)) {
      window.location.href =
        "kakaotalk://web/openExternal?url=" + encodeURIComponent(origin);
    } else if (agent.match(/line/i)) {
      window.location.href = `${origin}?openExternalBrowser=1`;
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <button
        className="rounded-md border-[0.6px] border-textColor bg-white px-5 py-3 text-lg font-semibold hover:bg-violet-100"
        onClick={() => openExternalBrowser()}
      >
        기본 브라우저에서 열기
      </button>
      <p className="text-[10px] font-light text-textLightColor">
        Access <span className="underline">Sopi</span> in your system browser
        for stable running.
      </p>
    </div>
  );
}
