"use client";

import ClosedAccountModal from "@/components/domain/auth/ClosedAccountModal";
import useErrorModal from "@/hooks/useErrorModal";
import { signIn, signOut, useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { FcGoogle } from "react-icons/fc";
import { logGaEvent } from "../../../service/gtag";

export default function GoogleSignInButton() {
  const { showErrorModal } = useErrorModal();
  const [showClosedAccountModal, setShowClosedAccountModal] = useState(false);
  const { data: session } = useSession();

  useEffect(() => {
    if (session) {
      const error = session.error;
      if (error) {
        signOut({ redirect: false });
        if (error === "Closed account") {
          setShowClosedAccountModal(true);
          return;
        }
        showErrorModal();
        return;
      }
    }
  }, [session, showErrorModal]);

  const handleGoogleSignIn = () => {
    logGaEvent({ action: "signin_click" });
    signIn("google", { redirect: false });
  };

  return (
    <div>
      <button
        className="flex items-center gap-2 rounded-full border-[0.6px] border-textColor bg-white px-3 py-2 text-lg font-bold sm:px-4 sm:py-3 sm:text-xl"
        onClick={handleGoogleSignIn}
      >
        <FcGoogle className="text-2xl sm:text-3xl" />
        Sign in with Google
      </button>
      {showClosedAccountModal && (
        <ClosedAccountModal onClose={() => setShowClosedAccountModal(false)} />
      )}
    </div>
  );
}
