"use client";

import RoundIconButtonWithBorder from "@/components/common/buttons/RoundIconButtonWithBorder";
import TextLinkButton from "@/components/common/buttons/TextLinkButton";
import DownloadAppToast from "@/components/common/toasts/DownloadAppToast";
import SignInButton from "@/components/domain/auth/SignInButton";
import SignInTitle from "@/components/domain/auth/SignInTitle";
import { PREV_PATH } from "@/constants/constants";
import { logGaEvent } from "@/service/gtag";
import { AUTH_TOKEN, setItemInLocalStorage } from "@/service/localStorage";
import { ClientSafeProvider, signOut, useSession } from "next-auth/react";
import { redirect, useSearchParams } from "next/navigation";
import { useEffect, useRef, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { BsChevronDoubleDown } from "react-icons/bs";
import { scroller } from "react-scroll";

import { Suspense } from "react";
import AuthErrorChecker from "./AuthErrorChecker";

interface Props {
  providers: Record<string, ClientSafeProvider> | null;
}

const introductionElement = "introduction";

export default function SignInPage({ providers }: Props) {
  const [scrolledDown, setScrolledDown] = useState(false);
  const { data: session } = useSession();
  const isAuth = session && !session.error;
  const [downloadAppToastShowed, setDownloadAppToastShowed] = useState(false);
  const downloadAppToastShowedRef = useRef<boolean>(false);

  useEffect(() => {
    const prevPath = globalThis.sessionStorage.getItem(PREV_PATH);
    if (isAuth) {
      logGaEvent({
        action: session.user.isNewAccount
          ? "signup_complete"
          : "signin_complete",
      });
      setItemInLocalStorage(AUTH_TOKEN, session.user.token);
      redirect(prevPath ? prevPath : "/");
    }
  }, [isAuth, session]);

  return (
  <main className="flex h-screen w-full flex-col items-center px-3 pb-11 pt-8">
    <Suspense fallback={<div>Loading...</div>}>
      <AuthErrorChecker />
    </Suspense>
    <TextLinkButton
      title=" "
      href="mailto:contact@tooeasy.life"
      target="_blank"
    />
    <div className="flex h-1/2 items-end justify-center">
      <SignInTitle />
    </div>
    <div className="flex flex-1 items-center justify-center">
      <SignInButton providers={providers} />
    </div>
  </main>
);
}
